body {
    margin: 10px;
}

.name, .email, .results, .type_test {
    padding: 2px;
}

.entriesTable {
    width: 100%;
}

.entryRow {
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}
